import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const Input = styled(TextField)({
  "& label.Mui-focused": {
    color: "#67c8f0",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#67c8f0",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#c3c3c3",
    },
    "&:hover fieldset": {
      borderColor: "#67c8f0",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#67c8f0",
    },
    "& .MuiInputBase-input": {
      fontFamily: ["Montserrat"],
    },
  },
});

