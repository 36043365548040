import Form from "./components/Form";
import logo from "../src/assets/Logo_Freewater.png";


function App() {
  return (
    <div className="app-wrapper">
      <img src={logo} alt="FreeWater Logo" className="brand-img"/>
      <Form />
    </div>
  );
}

export default App;
