import React from "react";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";

export const CustomSelect = styled(Select)({
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#c3c3c3",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#67c8f0",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#67c8f0",
  },

  "& .MuiInputBase-input": {
    fontFamily: ["Montserrat"],
  },
});
