import React, { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import validator from "validator";

// material ui components
import Box from "@mui/material/Box";
import MuiPhoneNumber from "material-ui-phone-number";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";

import { states, emailData } from "./data";

// jsx elements
import { Input } from "./Input";
import { CustomSelect } from "./Select";

const Form = () => {
  // state variables

  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [shippingStreet, setShippingStreet] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingZipCode, setShippingZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [quantity, setQuantity] = useState("");
  const [bottlesOrCartons, setBottlesOrCartons] = useState("");
  const [questions, setQuestions] = useState("");
  const [addressChecked, setAddressChecked] = useState(true);
  const [emailError, setEmailError] = useState("");

  const form = useRef();
  const regex = /^[0-9\b]+$/;

  // funstions that handle state change
  const handleSameAdressChange = (event) => {
    setAddressChecked(event.target.checked);
  };

  // const validateEmail = (e) => {
  //   var newEmail = e.target.value;
  //   if (validator.isEmail(newEmail)) {
  //     setEmail(newEmail);
  //     setEmailError("");
  //   } else {
  //     setEmailError("Please, enter valid email!");
  //   }
  // };
  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  const handleZipCodeChange = (e) => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setZipCode(e.target.value);
    }
  };
  const handleShippingZipCodeChange = (e) => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setShippingZipCode(e.target.value);
    }
  };
  const handleQuantity = (e) => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setQuantity(e.target.value);
    }
  };

  // function that clears the form after submit
  const clearForm = () => {
    setStreet("");
    setCity("");
    setState("");
    setZipCode("");
    setStreet("");
    setShippingCity("");
    setShippingState("");
    setShippingStreet("");
    setShippingZipCode("");
    setPhone("");
    setEmailError("");
    setQuantity("");
    setBottlesOrCartons("");
    setQuestions("");
    setName("");
    setOrgName("");
    setEmail("");
    setTimeout(() => {
      setStatus("");
    }, 4000);
  };

  // submit function
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        emailData.service,
        emailData.template,
        form.current,
        emailData.publicKey
      )
      .then(() => {
        setStatus({ type: "success" });
      })
      .catch((error) => {
        setStatus({ type: "error", error });
      });

    clearForm();
  };
  return (
    <form onSubmit={sendEmail} ref={form} className="form">
      <h3>General Information</h3>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "1fr 1fr" },
          gap: 2,
        }}
      >
        <Input
          id="name"
          label="Name"
          name="name"
          variant="outlined"
          value={name}
          required
          placeholder="Enter your name"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <Input
          required
          id="orgName"
          label="Organization Name"
          name="organizationName"
          value={orgName}
          variant="outlined"
          placeholder="Enter your Organization's name"
          onChange={(e) => {
            setOrgName(e.target.value);
          }}
        />
        <MuiPhoneNumber
          required
          id="phone"
          label="Phone"
          name="phone"
          value={phone}
          variant="outlined"
          defaultCountry={"us"}
          onChange={handlePhoneChange}
        />
        <Input
          type="email"
          required
          id="email"
          value={email}
          label="Email"
          name="email"
          variant="outlined"
          placeholder="Enter your email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <h3>Address</h3>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "1fr 1fr" },
          gap: 2,
        }}
      >
        <Input
          required
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          id="street"
          label="Street"
          name="street"
          variant="outlined"
          placeholder="Street Name"
        />
        <Input
          required
          value={city}
          onChange={(e) => setCity(e.target.value)}
          id="city"
          label="City"
          name="city"
          variant="outlined"
          placeholder="City"
        />

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">State *</InputLabel>
          <CustomSelect
            labelId="demo-simple-select-label"
            id="state"
            value={state}
            label="State *"
            required
            onChange={(e) => setState(e.target.value)}
            name="state"
          >
            {states.map((item) => (
              <MenuItem key={item.abbreviation} value={item.abbreviation}>
                {item.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
        <Input
          required
          value={zipCode}
          onChange={(e) => handleZipCodeChange(e)}
          id="zipCode"
          label="ZipCode"
          name="zipCode"
          variant="outlined"
          placeholder="Enter your ZipCode"
          inputProps={{ maxLength: 5, minLength: 5 }}
        />
      </Box>
      <h3>Information about containers</h3>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "1fr 1fr" },
          gap: 2,
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Type of Containers *
          </InputLabel>
          <CustomSelect
            labelId="demo-simple-select-label"
            id="bottlesOrCartons"
            value={bottlesOrCartons}
            label="Select your container"
            required
            onChange={(e) => setBottlesOrCartons(e.target.value)}
            name="bottlesOrCartons"
          >
            <MenuItem value="Aluminum">Aluminum Bottles</MenuItem>
            <MenuItem value="Paper Cartons">Paper Cartons</MenuItem>
          </CustomSelect>
        </FormControl>
        <Input
          required
          value={quantity}
          type="number"
          InputProps={{
            inputProps: {
              min: bottlesOrCartons === "Aluminum" ? 1600 : 10000,
            },
          }}
          onChange={(e) => handleQuantity(e)}
          id="quantity"
          label="Quantity"
          name="quantity"
          variant="outlined"
          placeholder="Enter quantity"
        />
      </Box>

      <h3>Shipping Address:</h3>
      <FormControlLabel
        control={
          <Checkbox
            checked={addressChecked}
            onChange={handleSameAdressChange}
            inputProps={{ "aria-label": "controlled" }}
            name="addressChecked"
            sx={{
              color: "#67c8f0",
              '&.Mui-checked': {
                color: "#67c8f0",
              },
              fontFamily: "Montserrat" 
            }}
          />
        }
        label="The same as address"
      />
      {addressChecked ? (
        <></>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr" },
            gap: 2,
          }}
        >
          <Input
            required
            value={shippingStreet}
            onChange={(e) => setShippingStreet(e.target.value)}
            id="shippingStreet"
            label="Street"
            name="shippingStreet"
            variant="outlined"
            placeholder="Street Name"
          />
          <Input
            required
            value={shippingCity}
            onChange={(e) => setShippingCity(e.target.value)}
            id="shippingCity"
            label="City"
            name="shippingCity"
            variant="outlined"
            placeholder="City"
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">State *</InputLabel>
            <CustomSelect
              id="shippingState"
              value={shippingState}
              label="State *"
              required
              onChange={(e) => setShippingState(e.target.value)}
              name="shippingState"
            >
              {states.map((item) => (
                <MenuItem
                  key={item.abbreviation}
                  value={item.abbreviation}
                  sx={{ fontFamily: "Montserrat" }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
          <Input
            required
            value={shippingZipCode}
            onChange={(e) => handleShippingZipCodeChange(e)}
            id="shippingZipCode"
            label="ZipCode"
            name="shippingZipCode"
            variant="outlined"
            placeholder="Enter your ZipCode"
            inputProps={{ maxLength: 5, minLength: 5 }}
          />
        </Box>
      )}
      <h3>Questions or comments</h3>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: " 1fr" },
          gap: 2,
        }}
      >
        <Input
          placeholder="Ask me anything"
          multiline
          minRows={3}
          maxRows={10}
          id="questions"
          value={questions}
          label="Questions?"
          name="questions"
          onChange={(e) => setQuestions(e.target.value)}
        />
      </Box>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          gap: "50px",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            backgroundColor: "#67c8f0",
            marginTop: "20px",
            width: "200px",
            height: "50px",
            fontFamily: "'Montserrat', sans-serif",
            boxShadow: "none",
            '&:hover': {
              backgroundColor: "#67c8f0",

            }
           
          }}
          type="submit"
          variant="contained"
          endIcon={<SendIcon />}
        >
          Send
        </Button>
        {status?.type === "success" && (
          <p
            style={{
              color: "#64ddb7",
              fontSize: "20px",
              fontWeight: 600,
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            The message has been sent!
          </p>
        )}
        {status?.type === "error" && (
          <p
            style={{
              color: "#bf1650",
              fontSize: "20px",
              fontWeight: 600,
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            Failed to send your message!
          </p>
        )}
      </div>
    </form>
  );
};

export default Form;
